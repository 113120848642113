<template>
  <div id="inspectorId">
    <div class="page-container mb-2">
      <h3
        class="header"
      >
        Bộ lọc
      </h3>
      <b-row>
        <!-- Huyện -->
        <b-col
          md="4"
          xl="4"
        >
          <b-form-group>
            <label
              for="basicInput"
            >Trạng thái</label>
            <v-select
              :reduce="label => label.value"
              label="name"
              :options="dataStatus || []"
              :placeholder="'Trạng thái'"
              @input="filterStatus"
            >
              <template v-slot:no-options="{ searching }">
                <template v-if="searching">
                  Không có kết quả.
                </template>
                <em
                  v-else
                >Không có dữ liệu</em>
              </template>
            </v-select>
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm tai nạn lao động'"
        :arrayExcel="[]"
        :showBtnMultiDelete="showBtnMultiDelete"
        :hideImportFile="false"
        :hideExportFile="false"
        :hideDowload="false"
        @clickDelete="deleteItems"
        @clickAdd="$router.push({ name: 'declare-accident-create' })"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column:Mã đơn vị -->
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>

          <span v-else-if="props.column.field == 'accidentTime'">
            <span>{{ props.row.accidentTime | formatDateToDDMMHHMM }}</span>
          </span>

          <!-- statusString : Trạng thái  -->
          <span
            v-else-if="props.column.field == 'accidentStatusString' "
          >
            <b-badge
              pill
              :variant="statusVariant(props.row.accidentStatus)"
              class="border-status"
            >
              <span> {{ props.row.accidentStatusString }}</span>

            </b-badge>

          </span>

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-if="props.row.accidentStatus === 'NotSend'"
                v-b-tooltip.hover.top="'Bổ sung hồ sơ xử lý tai nạn'"
                icon="FileTextIcon"
                size="18"
                class="text-body mr-2"
                @click="openModalAddFile(props.row.id)"
              />

              <feather-icon
                v-if="props.row.accidentStatus !== 'NotSend'"
                v-b-tooltip.hover.top="'Bổ sung hồ sơ xử lý tai nạn'"
                icon="FileTextIcon"
                size="18"
                class="text-body mr-2"
                @click="openModalSeeFile(props.row.id)"
              />

              <span class="disable-icon">
                <feather-icon
                  v-if="props.row.filePath"
                  v-b-tooltip.hover.top="'Tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  class="text-body"
                  @click="downloadFile(props.row.id, props.row.fileName)"
                />
                <feather-icon
                  v-if="!props.row.filePath"
                  v-b-tooltip.hover.top="'Không có file tải xuống'"
                  icon="DownloadIcon"
                  size="18"
                  class="text-disable"
                />
              </span>

              <span v-if="!['Send'].includes(props.row.accidentStatus)">
                <b-dropdown
                  variant="link"
                  boundary="viewport"
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template v-slot:button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body"
                    />
                  </template>
                  <b-dropdown-item
                    v-if="['NotSend', 'Reject'].includes(props.row.accidentStatus)"
                    @click="$router.push({ name: 'declare-accident-edit', params: { id: props.row.id } })"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>Chỉnh sửa</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['NotSend', 'Reject'].includes(props.row.accidentStatus)"
                    @click="openModalSend(props.row.id)"
                  >
                    <feather-icon
                      icon="SendIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>Gửi khai báo tai nạn</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Reject'].includes(props.row.accidentStatus)"
                    @click="openModalReject(props.row.id)"
                  >
                    <feather-icon
                      icon="CornerUpLeftIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>{{ 'Nội dung trả lại' }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item
                    v-if="['Approve'].includes(props.row.accidentStatus)"
                    @click="openModalContent(props.row.id)"
                  >
                    <feather-icon
                      icon="CheckCircleIcon"
                      size="16"
                      class="text-body mr-50"
                    />
                    <span>{{ 'Nội dung xử lý' }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </span>
            </span>

          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <!-- modal gửi báo cáo-->
      <confirm-modal
        :id="confirmModalSendId"
        :content="'Bạn có muốn gửi khai báo tai nạn'"
        :title="'Bạn có chắc chắn gửi khai báo?'"
        @accept="sendAction"
      />

      <!--Tạo file đính kèm-->
      <modal-create-file
        :id="modalCreateFile"
        :dataAddFile="dataAddFile"
        :totalRecordAddFile="totalRecordAddFile"
        :queryParams="queryParams"
        @handlePageClickPage="handlePageClickPage"
        @handleAddFile="handleAddFile"
        @deleteFile="deleteFile"
        @searchFile="searchFile"
      />

      <!--Tạo file đính kèm-->
      <modal-see-file
        :id="modalSeeFile"
        :dataAddFile="dataAddFile"
        :totalRecordAddFile="totalRecordSeeFile"
        :queryParams="queryParams"
        @searchFile="searchSeeFile"
      />

      <!--Nội dung trả lại-->
      <modal-show-reject
        :id="confirmModalRejectId"
        :dataInfo="dataInfo"
      />

      <!--Nội dung xử lý-->
      <modal-show-content
        :id="confirmModalContentId"
        :dataInfo="dataInfo"
      />
    </div>
  </div>
</template>

<script>
import { formatFullName, formatDateToDDMMHHMM } from '@core/utils/filter'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalShowReject from './components/ModalShowReject.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'
import ModalCreateFile from './components/ModalCreateFile.vue'
import ModalSeeFile from './components/ModalSeeFile.vue'
import ModalShowContent from './components/ModalShowContent.vue'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    ModalCreateFile,
    VBTooltip,
    VSelect,
    ModalShowReject,
    ModalShowContent,
    ModalSeeFile,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMMHHMM,
  },
  data() {
    return {
      modalSeeFile: 'confirm-see-file',
      modalCreateFile: 'comfirm-create-filess',
      confirmModalSendId: 'confirm-modal-sendId',
      confirmModalRejectId: 'confirm-modal-reject',
      confirmModalContentId: 'confirm-modal-content',
      totalRecord: 0,
      dataTable: [],
      columns: [
        // {
        //   label: 'MÃ TAI NẠN Lao Động',
        //   field: 'code',
        //   sortable: false,
        // },
        {
          label: 'THỜI GIAN XẢY RA VỤ TAI NẠN',
          field: 'accidentTime',
          sortable: false,
        },
        {
          label: 'NƠI XẢY RA TAI NẠN',
          field: 'location',
          sortable: false,
        },
        {
          label: 'TRẠNG THÁI',
          field: 'accidentStatusString',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          sortable: false,
          width: '150px',
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
        isBussiness: true,
        status: null,
      },
      queryParams: {
        accidentId: '',
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      deleteIds: [],
      showBtnMultiDelete: false,
      totalRecordAddFile: 0,
      totalRecordSeeFile: 0,
      dataAddFile: [],
      dataStatus: [
        {
          value: 'NotSend',
          name: 'Chưa gửi',
        },
        {
          value: 'Send',
          name: 'Đã gửi',
        },
        {
          value: 'Approve',
          name: 'Đã xử lý',
        },
        {
          value: 'Reject',
          name: 'Trả lại',
        },
      ],
      accidentId: null,
      dataAccidentId: null,
      dataInfo: {
        content: '',
        reasonReject: '',
      },
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        NotSend: 'secondary',
        Send: 'primary',
        Approve: 'success',
        Reject: 'danger',
      }
      return status => statusColor[status]
    },
  },

  watch: {
    dataAccidentId(val) {
      if (val) {
        this.dataInfo = {
          content: this.dataAccidentId.content,
          reasonReject: this.dataAccidentId.reasonReject,
        }
      }
    },
  },

  created() {
    this.fetchAccidentBusiness(this.urlQuery)
  },
  methods: {
    // Download file
    downloadFile(id, name) {
      axiosApiInstance({
        url: ConstantsApi.DOWNLOAD_ACCIDENT, // your url
        method: 'GET',
        responseType: 'blob', // important
        params: { id },
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name) // or any other extension
        document.body.appendChild(link)
        link.click()
      })
    },

    // Danh sách tai nạn lao động
    async fetchAccidentBusiness(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_ACCIDENT_BUSINESS, {
        params: urlQuery,
      })

      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    // Lấy thông tin khai báo tai nạn lao động theo id
    async openModalReject(id) {
      this.$bvModal.show(this.confirmModalRejectId)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ACCIDENT_BUSINESS}${id}`)
      this.dataAccidentId = data
    },

    // Mở modal nội dung xử lý
    async openModalContent(id) {
      this.$bvModal.show(this.confirmModalContentId)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_ACCIDENT_BUSINESS}${id}`)
      this.dataAccidentId = data
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchAccidentBusiness(this.urlQuery)
    },

    filterStatus(val) {
      this.urlQuery.pageNumber = 1
      this.urlQuery.status = val
      this.fetchAccidentBusiness(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchAccidentBusiness(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchAccidentBusiness(this.urlQuery)
      }
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // modal Gửi báo cáo
    openModalSend(id) {
      this.accidentId = id
      this.$bvModal.show(this.confirmModalSendId)
    },

    // Gửi báo cáo
    async sendAction() {
      const model = {
        id: this.accidentId,
      }
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.SEND_ACCIDENT, model).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchAccidentBusiness(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Gửi không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_ACCIDENT_BUSINESS, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchAccidentBusiness(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // --------- FILE -----------

    // Mở modal thêm file
    async openModalAddFile(id) {
      this.queryParams.accidentId = id
      await this.fetchFileAccident(this.queryParams)
      this.$bvModal.show(this.modalCreateFile)
    },

    // Mở modal xem danh sách file
    async openModalSeeFile(id) {
      this.queryParams.accidentId = id
      await this.fetchFileAccident(this.queryParams)
      this.$bvModal.show(this.modalSeeFile)
    },
    // Danh sách file để thêm
    async fetchFileAccident(queryParams) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_ACCIDENT_ADD_FILE, {
        params: queryParams,
      })
      this.dataAddFile = data.data.pageLists
      this.totalRecordAddFile = data.data.totalRecord
      this.$hideLoading()
    },
    // Xử lý khi ấn nút phân trang thêm file
    handlePageClickPage(pageNumber, pageSize) {
      this.queryParams.pageNumber = pageNumber
      this.queryParams.pageSize = pageSize
      this.fetchFileAccident(this.queryParams)
    },
    // lấy danh sách khi tìm kiếm file
    searchFile(val) {
      if (val != null) {
        this.queryParams.pageNumber = 1
        this.queryParams.key = val
        this.fetchFileAccident(this.queryParams)
      } else {
        this.queryParams.pageNumber = 1
        this.queryParams.key = ''
        this.fetchFileAccident(this.queryParams)
      }
    },

    searchSeeFile(val) {
      if (val != null) {
        this.queryParams.pageNumber = 1
        this.queryParams.key = val
        this.fetchFileAccident(this.queryParams)
      } else {
        this.queryParams.pageNumber = 1
        this.queryParams.key = ''
        this.fetchFileAccident(this.queryParams)
      }
    },

    // Thêm file vào giấy phép lao động
    async handleAddFile(val) {
      val.append('AccidentId', this.queryParams.accidentId)
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.ADD_FILE_TO_ACCIDENT, val).then(res => {
        this.$hideAllPageLoading()
        if (res.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.fetchFileAccident(this.queryParams)
        } else {
          this.$hideAllPageLoading()
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },

    // Xóa file trong giấy phep lao dộng
    async deleteFile(val) {
      this.$hideAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_FILE_TO_ACCIDENT, {
        ids: val,
        accidentId: this.queryParams.accidentId,
      }).then(() => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchFileAccident(this.queryParams)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$root.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
  },
}
</script>

<style lang="scss">
#inspectorId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }

  .text-disable {
    color: #797E84;
  }

//   @media (max-width: 425px) {
//   .search-input {
//     width: unset !important;
//     margin: 0 1.5rem 1rem 1.5rem;
//   }
// }
}
</style>

<template>
  <b-modal
    :id="id"
    size="sm"
    title="Xem nội dung xử lý"
    centered
    hide-footer
  >
    <div class="modal-accept">
      <b-form-group>
        <label>Nội dung xử lý</label>
        <b-form-textarea
          id="InputHelp"
          v-model="dataInfo.content"
          rows="3"
          no-resize
          disabled
        />
      </b-form-group>
    </div>
  </b-modal>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex'

import {
  BFormGroup, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    isNotification: {
      type: Boolean,
      default: false,
    },
    dataInfo: {
      type: Object,
      default: _ => {},
    },
  },
}
</script>

<style lang="scss">
.modal-accept {
  .btn-button-file {
    width: 45%;
    height: 38px;
  }
      .input-file {
      display: none;
    }
}
</style>
